<template>
    <div class="vip-content">
        <div class="vip-title">
            <img src="@/assets/img/i-tit2.png" alt="">
<!--            客户服务 (大宗询价、COA下载说明)-->
			{{helpInfo.title}}
        </div>
<!--		暂注-->
        <div v-if="false" class="vip-content-inner">
            <div class="rich-text">
               <p>大宗询价</p>
                <br>
               <p>大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗022 58613872大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价大宗询价</p>
                <br>
               <p>COA下载说明</p>
                <br>
               <p>COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说COA下载说明COA下载说明COA下载说COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明OA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明CQA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明COA下载说明</p>

            </div>
        </div>

		<div v-if="helpInfo">
			<div v-html="helpInfo.contentText"></div>
		</div>

	</div>
</template>

<script>
import {articleShoppingHelpDetail} from "@/api/CMSmodule";
import Bus from "@/utils/bus";

export default {
    name: "registerAndLogin",
	data(){
    	return{
    		helpInfo:{}, // 帮助详情

		}
	},
	mounted(){
		// console.log(this.$route.query) // 获取传递参数
		Bus.$off('shoppingHelp') // 注销
		Bus.$on("shoppingHelp", (info) => { // 接收搜索关键词
			let that=this
			console.log(info)
			this.getarticleShoppingHelpDetail(info)
		})
	},
	methods:{

		//  -------------------------接口请求
		getarticleShoppingHelpDetail(id){ // 获取购物帮助列表
			articleShoppingHelpDetail({id:id}).then((res) => {
				if (res.data.code==200) {
					this.helpInfo=res.data.data
					this.helpInfo.contentText=this.$utils.showHtml(res.data.data.contentText)
				}else{

				}

			})
		},

	},
}
</script>

<style scoped>

</style>
